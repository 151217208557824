<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @click.native="clear()"
    @update:show="(value) => {$store.commit('set', ['sidebarShow', value]);}"
  >
    <CSidebarBrand class="d-md-down-none"  @click.native="clearThePayLoad">
      <img
        src="../../public/logo/logo.svg"
        width="100%"
        height="100%"
        v-if="!minimize"
        style="background: #fff; height: 56px !important"
      />
      <img
        src="../../public/logo/logo_icon.png"
        width="100%"
        height="100%"
        v-else
        style="background: #fff"
      />
    </CSidebarBrand>

    <CRenderFunction
      flat
      :content-to-render="roleID == 5 ? $options.nav.ACL1 : roleID == 8 ?  $options.nav.ACL2: $options.nav.ACL3"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    roleID() {
      return this.$store.state.auth.roleID;
    },
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    clear()
    {
    this.$store.commit('clearPayloads');
    }
  }
};
</script>
