<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0 pb-0">
    <template #toggler>
      <CHeaderNavLink>
        <b-avatar variant="info" draggable="false" v-bind:src="user.photo ? user.photo : logoImage"></b-avatar>
      </CHeaderNavLink>
    </template>

    <CDropdownItem to="/profile">
      <CIcon name="cil-user" /> {{ "Profile" }}
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem to="/login/resetpwd">
      <CIcon name="cil-lock-locked" /> Reset Password
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" />
      {{ "Logout" }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { LOGOUT } from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      logoImage: `${process.env.BASE_URL}img/avatars/default.png`,
    };
  },
  methods: {
    async logout() {
      this.$store.state.sidebarMinimize = false;
      /* payload to delete fcm token */
      let payload = {
        type: "admin",
      };

      this.$store.dispatch(LOGOUT, payload);
      localStorage.clear();
      this.$router.push({
        name: "Login",
      });
    },
  },

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}

.dropdown-divider {
  margin: 0rem 0 !important;
}

.dropdown-menu {
  padding: 0rem 0 !important;
}
</style>
