<template>
  <CFooter :fixed="false">
    <div class="row mt-lg-3 mt-md-3 mt-0 mt-xl-3 mt-sm-3">
      <div class="col-lg-7 col-xl-8 col-md-7 col-12">
        <span class="ml-1"
          >&copy; {{ new Date().getFullYear() }} Mitsubishi Electric India Pvt.
          Ltd.</span
        >
      </div>
      <div class="col-lg-5 col-xl-4 col-md-5 col-12">
        <div class="d-flex pull-right">
          <div class="pr-2">
            <router-link to="/terms_of_use" target="_blank">
              Terms of Use
            </router-link>
          </div>
          |
          <div class="pl-2">
            <router-link to="/privacy_policy" target="_blank">
              Privacy Policy
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
<style scoped>
.c-footer {
  font-size: 16px !important;
   font-family: "Roboto", sans-serif;
  display: block !important;
}

.c-footer a {
  text-decoration: none;
  background-color: transparent;
  color: #3c4b64 !important;
  font-size: 16px !important;
  font-family: "Roboto", sans-serif;
}

.c-footer a:hover {
  color: #c40001 !important;
  text-decoration: none;
}

@media (max-width: 321px) {
  .c-footer .d-flex {
    display: flex !important;
  }
}

@media (max-width: 425px) {
  .c-footer .pull-right {
    float: left;
  }
}
</style>
