<template>
  <CHeader with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="d-md-down-none menu-open-class" v-if="!minimize">
        <div class="website-title">ME-Connect</div> 
      </CHeaderBrand>
      <CHeaderBrand class="d-md-down-none" v-if="minimize">
        <div class="website-title">ME-Connect</div> 
      </CHeaderBrand>
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img
        src="../../public/logo/mobilenew.png"
        width="125px"
        height="50px"
        style="max-width: 174px"
        class="container-fluid"
      />
    </CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>

    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>
